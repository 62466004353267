<template>
  <div class="referrals" @click.prevent="$refs.floatingbutton.close()">
    <div class="filters">
      <div class="filters-container">
        <Multiselect
          v-model="filters.tableType"
          class="search-select"
          :options="[{value:0, text: 'Consumo credito'}, {value:1, text: 'Ricariche credito'}]"
          :placeholder="'Filtra per tipologia'"
          :can-deselect="true"
          :hide-selected="true"
          :can-clear="true"
          track-by="value"
          label="text"
          @change="getTableContent()"
        >
          <template #option="{ option }">
            <span>{{ option.text }} </span>
          </template>
        </Multiselect>

        <select
          v-if="false"
          v-model="filters.tableType"
          class="filterType"
          @change="getTableContent()"
        >
          <option value="0">
            Consumo credito
          </option>
          <option value="1">
            Ricariche credito
          </option>
        </select>

        <div class="search">
          <label for="search">Cerca</label>
          <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
        </div>
      </div>
      <div class="filters-container">
        <div class="date-box">
          <label for="date-from">Data inizio</label>
          <input id="date-from" type="date" @input="dateFromChanged($event)">
        </div>

        <div class="date-box">
          <label for="date-to">Data fine</label>
          <input id="date-to" type="date" @input="dateToChanged($event)">
        </div>

        <div class="lens">
          <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
        </div>
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header)">
              <div class="">
                <span>{{ header }}</span>
                <font-awesome-icon v-if="filters.order.field == header" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="log in getFilteredRows()" :key="log.id">
            <td><span>{{ dayjs(log.data).format('DD/MM/YYYY') }}</span></td>
            <td><span>{{ log.ragioneSociale }}</span></td>
            <td><span>{{ log.piva }}</span></td>
            <td><span>{{ log.codiceFiscale }}</span></td>
            <td><span>{{ log.netto ?? 0 }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <floating-button ref="floatingbutton" :menu="true" :list="[{name:'Ricarica credito CSV', payload:'companyPayout'}, {name:'Pagamento aziende CSV', payload:'companyPayments'}]" :tooltip="'Scarica i report'" @action="getCSV($event)" />
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/it'
import csvDownload from 'json-to-csv-export'
import { reportbackoffice, sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive, ref } from '@vue/reactivity'
import { inject, onMounted } from '@vue/runtime-core'
import dayjs from 'dayjs'
import Multiselect from '@vueform/multiselect'
// import FloatingButton from '../../components/FloatingButton.vue'

export default {
  components: {
    Multiselect
  },
  setup() {
    let companies = ref([])
    const filters = reactive({
      order: {
        field: 'Data di sblocco',
        asc: false
      },
      search: [],
      dateFrom: null,
      dateTo: null,
      tableType: 0 // 0: consumo credito, 1: ricariche credito 
    })
    const headers = ['Data di sblocco', 'Ragione Sociale', 'P.IVA', 'CF', '€ Azienda']
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const toast = inject('$toast')

    const getTableContent = () => {
      spinner.show()
      
      sbapibackoffice
        .get(filters.tableType === 0 ? '/accounting/companypayments' : '/accounting/companypayout', {
          withCredentials: true
        })
        .then((response) => {
          companies.value = response.data
          if(companies.value.length === 0){
            let text = 'Nessun risultato trovato per la tipologia '
            text += filters.tableType === 0 ?'"consumo credito"':'ricarica credito'+'!'
            text += '<br>Modifica i filtri!'
            toast.error(text)
          }
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => spinner.hide())

      spinner.hide()
    }

    /**
		 * Set the order condition for the table
		 * @param {number} orderBy - the column to order by
		 */
    const changeOrder = (orderBy) => {
      if (filters.order.field == orderBy) {
        filters.order.asc = !filters.order.asc
      } else {
        filters.order.field = orderBy
        filters.order.asc = true
      }
    }

    /**
		 * manage the search input
		 * @param {Event} event - the oninput input event
		 */
    const filterSearch = ({ target }) => {
      filters.search = target.value ? target.value.split(' ') : []
    }

    const getFilteredRows = () => {
      const rows = companies.value
        .filter((f) => {
          // if the filter is empty each row is allowed
          if (!filters.search.length) return true

          let allFiltersArePresentInRow = true

          for (const filter of filters.search) {
            let n = f.ragione_sociale.toLowerCase().includes(filter.toLowerCase())
            let u = f.piva.toLowerCase().includes(filter.toLowerCase())

            allFiltersArePresentInRow = allFiltersArePresentInRow && (n || u)
            if (!allFiltersArePresentInRow) break
          }

          return allFiltersArePresentInRow
        })
        .filter(({ date }) => (!filters.dateFrom ? true : date >= filters.dateFrom))
        .filter(({ date }) => (!filters.dateTo ? true : date <= filters.dateTo))
        .sort(orderByColumnValues)

      return rows
    }

    const orderByColumnValues = (a, b) => {
      switch (filters.order.field) {
        
        case headers[0]:
          if (a.data < b.data) return filters.order.asc ? -1 : 1
          if (a.data > b.data) return filters.order.asc ? 1 : -1
          break

        case headers[1]:
          if ((a.ragioneSociale).toLowerCase() < (b.ragioneSociale).toLowerCase()) return filters.order.asc ? -1 : 1
          if ((a.ragioneSociale).toLowerCase() > (b.ragioneSociale).toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[2]:
          if (a.piva.toLowerCase() < b.piva.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.piva.toLowerCase() > b.piva.toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[3]:
          if (a.codiceFiscale.toLowerCase() < b.codiceFiscale.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.codiceFiscale.toLowerCase() > b.codiceFiscale.toLowerCase()) return filters.order.asc ? 1 : -1
          break

        case headers[4]:
          if (a.netto < b.netto) return filters.order.asc ? -1 : 1
          if (a.netto > b.netto) return filters.order.asc ? 1 : -1
          break

        default:
          return 0
      }
      return 0
    }

    onMounted(async () => {
      navbar.title = 'Referenziatori'
      
      getTableContent()
    })
    const getCSV = (type) =>{
      spinner.show()
      reportbackoffice.get('/accounting/csv?reportType='+type)
        .then((response) => {
          console.log(response)
          let array = response.data.split('\r\n')
          let header = JSON.parse(JSON.stringify(array[0])).split(',')
          array.shift() 

          let dataFormatted = []
          array.forEach((el, index)=> {
            let data = el.split(',')
            data.forEach((record, i)=>{
              let k = header[i]
              if(dataFormatted[index]) {
                dataFormatted[index][k] = record
              } else {
                dataFormatted.push({
                  [k] :record
                })
              }
            })
          })
          const dataToConvert = {
            data: dataFormatted,
            filename: type==='companyPayout'? 'ricarica-credito__'+moment().format('DD-MM-YY') : 'pagamento-aziende__'+moment().format('DD-MM-YY'),
            delimiter: ',',
            headers: header
          }
          console.log(dataToConvert)
          csvDownload(dataToConvert)
        })
        .catch((error) => {
          
          toast.error('Qualcosa è andato storto, ' + error)
        })
        .finally(() => spinner.hide())
    }
    return {
      companies,
      filters,
      headers,
      spinner,
      navbar,
      changeOrder,
      filterSearch,
      getFilteredRows,
      dayjs,
      getCSV,
      getTableContent
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.filters-container{
.multiselect-dropdown {
  background: #004278;
  color:white;
  overflow: hidden;
}
.multiselect-option,
.multiselect-option.is-selected.is-pointed {
  background: #004278!important;
  color:white!important;
  }
  .multiselect-option.is-selected {
    display: none!important;
  }
  .multiselect-option:hover {
  background: #1a73a3!important;
  color:white!important;
  }
  .multiselect-caret{
    background-color: white!important;
  }
}
.filterType{
  width: 100%;
  padding: 10px;
  color: white;
  background-color: transparent;
  border: thin solid rgba(244, 244, 244, 0.5333333333);
  border-radius: 5px;
  user-select: none;
  outline:none;
  box-shadow: none;
  *:focus-visible,
  *:focus{
    outline:none;
    box-shadow: none;
  }
}
</style>
<style lang="scss" scoped>
@use '../../assets/scss/referrals';
@use '../../assets/scss/table';

.filters {
  flex-direction: column;
  .filters-container {
    width: 100%;
    display: flex;
    gap:10px;
    &:first-of-type{
      padding-right: 30px;
    }
    div.search,
    div.date-box{
      width: 100%;
    }
  }
}

.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th{
  border-bottom-left-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child{
  padding-left:1.5rem!important;
}
.text-xs{
  font-size:13px;
  margin-top:4px;
}

table {
  th {
    &:nth-child(1) {
      width: 100px;
    }
  }
}
</style>
